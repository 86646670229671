import { css } from '@emotion/react';
import { ActionLink, MediumHeadline } from '@minted/fancyclothes';
import { theme } from '@minted/minted-components';
import isEqual from 'lodash/isEqual';
import {
  bool, array, string,
} from 'prop-types';
import React from 'react';

import EventDetail from './EventDetail';
import ListNumber from './ListNumber';
import { contentConfiguration, contentStyles } from '../../content';
import {
  ContentText,
  ContentWrapper,
  HeadlineSmall, HeadlineTiny,
  Image,
  RichTextContent,
} from '../../content/components';
import { BLANK_SLATE_DOCUMENT, SITE_TYPES } from '../../core/constants';
import { getEventDateTimeComponent } from '../utils';

const wordWrapStyles = css`
  overflow: hidden;
`;

const eventHeadingStyles = css`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const eventNumMarginStyles = css`
  margin-right: ${theme.spacing.x3};
`;

const eventStyles = {
  eventInfo: css`
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin-top: ${theme.spacing.x4};
    margin-bottom: ${theme.spacing.x8};
    ${theme.media.lessThan(theme.breakpoints.medium)(`
      flex-direction: column;
      align-items: center;
    `)};
  `,
  infoBlock: css`
    width: 30%;
    ${theme.media.lessThan(theme.breakpoints.medium)(`
      margin-bottom: ${theme.spacing.x4};
      width: 100%;
    `)};
  `,
  infoValue: css`
    margin-top: ${theme.spacing.x3};
    margin-bottom: ${theme.spacing.x4};
  `,
};

const EventListNumber = ({
  extraCss, number, showListNumber,
}) => (
  showListNumber
    ? (
      <div css={extraCss}>
        <ListNumber>
          {number}
        </ListNumber>
      </div>
    )
    : null
);

const Event = ({
  address1,
  address2,
  city,
  configuration,
  date,
  description,
  endDate,
  endTime,
  formattedAddress,
  hasPhysicalAddress,
  hostedBy,
  image,
  index,
  showListNumber,
  siteType,
  state,
  time,
  title,
  venue,
  zipCode,
}) => {
  if (siteType === SITE_TYPES.WEDDING) {
    return (
      <ContentWrapper
        extraCss={
          [
            contentStyles.relative,
            contentStyles.mx0Md,
          ]
        }
        imageAlignment={configuration.imageAlignment}
        textAlignment={configuration.textAlignment}
      >

        <Image
          image={image}
          imageAlignment={configuration.imageAlignment}
          imageSize={configuration.imageSize}
        />

        <ContentText
          extraCss={
            [
              contentStyles.flex,
              contentStyles.flexRow,
            ]
          }
          textAlignment={configuration.textAlignment}
        >
          <div
            css={
              [
                image ? contentStyles.flex5 : contentStyles.flex9,
                wordWrapStyles,
              ]
            }
          >
            <div
              css={eventHeadingStyles}
              data-cy="subheading"
            >
              <EventListNumber
                extraCss={eventNumMarginStyles}
                number={index + 1}
                showListNumber={showListNumber}
              />
              <HeadlineSmall>
                {title}
              </HeadlineSmall>
            </div>
            <div data-cy="body">
              <EventDetail
                address={formattedAddress}
                date={date}
                endDate={endDate}
                endTime={endTime}
                time={time}
                venue={venue}
              />

              {
                description
                && (
                  <RichTextContent
                    value={description}
                  />
                )
              }

              {
                hasPhysicalAddress
                && (
                  <ActionLink
                    target="_blank"
                    text="Get Directions"
                    url={
                      'https://www.google.com/maps/dir/?api=1&destination='.concat(encodeURIComponent(formattedAddress))
                    }
                  />
                )
              }
            </div>
          </div>
        </ContentText>
      </ContentWrapper>
    );
  } else if (siteType === SITE_TYPES.ONLINE_INVITATION) {
    return (
      <ContentWrapper
        extraCss={
          [
            contentStyles.relative,
            contentStyles.mx0Md,
          ]
        }
        imageAlignment={configuration.imageAlignment}
        textAlignment={configuration.textAlignment}
      >
        <div css={eventStyles.eventInfo}>
          {
            hostedBy && (
              <div css={eventStyles.infoBlock}>
                <HeadlineTiny>
                  Hosted By
                </HeadlineTiny>
                <div css={eventStyles.infoValue}>
                  {hostedBy}
                </div>
              </div>
            )
          }
          <div css={eventStyles.infoBlock}>
            <HeadlineTiny>
              Date
            </HeadlineTiny>
            <div css={eventStyles.infoValue}>
              {
                getEventDateTimeComponent(date, time, endDate, endTime)
              }
            </div>
          </div>
          {
            venue && (
              <div css={eventStyles.infoBlock}>
                <HeadlineTiny>
                  Address
                </HeadlineTiny>
                <div css={eventStyles.infoValue}>
                  {venue}
                  {
                    hasPhysicalAddress && (
                      <>
                        <br />
                        {address1 || ''}
                        {address2 ? `, ${address2}` : ''}
                        <br />
                        {(city && state && zipCode) ? `${city}, ${state} ${zipCode}` : ''}
                      </>
                    )
                  }
                </div>
              </div>
            )
          }
        </div>
        {
          (description && !isEqual(description, BLANK_SLATE_DOCUMENT)) && (
            <div>
              <MediumHeadline>
                Schedule of Events
              </MediumHeadline>
              <RichTextContent
                value={description}
              />
            </div>
          )
        }
      </ContentWrapper>
    );
  }
};

Event.propTypes = {
  address1: string,
  address2: string,
  city: string,
  configuration: contentConfiguration.configurationPropTypes,
  date: string.isRequired,
  description: array,
  endDate: string,
  endTime: string,
  formattedAddress: string,
  hasPhysicalAddress: bool,
  hostedBy: string,
  showListNumber: bool,
  siteType: string.isRequired,
  state: string,
  time: string,
  title: string,
  venue: string,
  zipCode: string,
};

export default Event;
